import { render, staticRenderFns } from "./LicenseAgreementAuto.vue?vue&type=template&id=53d49b78&scoped=true"
import script from "./LicenseAgreementAuto.vue?vue&type=script&lang=ts"
export * from "./LicenseAgreementAuto.vue?vue&type=script&lang=ts"
import style0 from "./LicenseAgreementAuto.vue?vue&type=style&index=0&id=53d49b78&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d49b78",
  null
  
)

export default component.exports