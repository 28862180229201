import { render, staticRenderFns } from "./AppBottomMenu.vue?vue&type=template&id=52b44f60&scoped=true"
import script from "./AppBottomMenu.vue?vue&type=script&lang=ts"
export * from "./AppBottomMenu.vue?vue&type=script&lang=ts"
import style0 from "./AppBottomMenu.vue?vue&type=style&index=0&id=52b44f60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b44f60",
  null
  
)

export default component.exports