
import { Component, Vue } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import Translation from "@/components/Translation.vue";
import { Localizer } from "@/utils/Localizer";
import Telegram from "@/utils/Telegram";

@Component({ components: { Button, i18n: Translation } })
export default class HomeLugansk extends Vue {
  mounted(): void {
    console.log(window.navigator);
    this.vk_tag();
    this.$store.state.isLugansk = true;
    if (this.$refs.big && this.$refs.big instanceof HTMLVideoElement) {
      this.$refs.big.play();
    }
    if (this.$refs.small && this.$refs.small instanceof HTMLVideoElement) {
      this.$refs.small.play();
    }
  }

  conversion() {
    //@ts-ignore
    window.gtag_report_conversion();
  }

  vk_tag() {
    //@ts-ignore
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({ id: "3403583", type: "pageView", start: new Date().getTime() });
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script");
      ts.type = "text/javascript";
      ts.async = true;
      ts.id = id;
      ts.src = "https://top-fwz1.mail.ru/js/code.js";
      //@ts-ignore
      var f = function () {
        var s = d.getElementsByTagName("script")[0];
        //@ts-ignore
        s.parentNode.insertBefore(ts, s);
      };
      //@ts-ignore
      if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
      } else {
        f();
      }
    })(document, window, "tmr-code");
  }

  getImage(name: string): string {
    return Localizer.getLocalizedImageLink(
      name,
      this.$store.state.currentLanguage.id
    );
  }

  downloadPage(website = false) {
    if (website) {
      window.open("/assets/carbery.apk", "_blank");
    } else {
      this.conversion();
      window.open(
        "https://redirect.appmetrica.yandex.com/serve/171331191864127036",
        "_blank"
      );
    }
  }
}
