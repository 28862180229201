import { render, staticRenderFns } from "./PrivacyPolicyEn.vue?vue&type=template&id=43ddc230&scoped=true"
import script from "./PrivacyPolicyEn.vue?vue&type=script&lang=ts"
export * from "./PrivacyPolicyEn.vue?vue&type=script&lang=ts"
import style0 from "./PrivacyPolicyEn.vue?vue&type=style&index=0&id=43ddc230&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43ddc230",
  null
  
)

export default component.exports