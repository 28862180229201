
import { Component, Vue } from "vue-property-decorator";
import ProjectSelect from "@/components/ProjectSelect.vue";
import Translation from "@/components/Translation.vue";

@Component({
  name: "app-header",
  components: { ProjectSelect, Translation },
})
export default class AppHeader extends Vue {
  openProjectSelect(): void {
    this.$store.commit("displayProjectList");
  }
}
