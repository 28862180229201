var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
    'button',
    _vm.type,
    _vm.size,
    _vm.block && 'block',
    _vm.bordered && 'bordered',
    _vm.disabled && 'disabled',
    `text-${_vm.text}`,
  ],attrs:{"disabled":_vm.disabled,"type":"button","aria-disabled":_vm.disabled}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }