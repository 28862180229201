<template>
  <div class="w3-container">
    <p>Минимальная стоимость проезда по городу составляет 200 руб.</p>
    <p>Цена каждого последующего километра по городу - 35 руб.</p>
    <p>
      Во время пиковой нагрузки действует дополнительная наценка на поездку в
      пределах города и его отдаленных районов 40 руб.
    </p>
    <p>
      В случае ухудшения погодных условий, препятствующих передвижению не полно
      приводными видами легкового автотранспорта, на поездку в пределах города и
      его отдаленных районов действует дополнительная наценка до 100 руб.
    </p>
    <p>Цена проезда 1 км за городом - 50 руб.</p>
    <p>
      Ожидание автомобиля при подаче свыше 5 мин. - 5 руб./мин. (При заказе на
      определенное время, бесплатного ожидания нет) + 20 руб. – Непредвиденная
      остановка до 3 мин., каждая последующая минута – 5 руб.
    </p>
  </div>
</template>

<script>
export default {
  name: "LegalTariff",
};
</script>

<style lang="scss" scoped>
* {
  text-align: left;
}

h1,
h2,
h3 {
  font-size: 1.7rem;
  margin-top: 32px;
  margin-bottom: 8px;
  line-height: 2.5rem;
}

.w3-container {
  padding: 40px 7.5vw;
  background-color: #fff;
}
</style>
