
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import * as data from "@/components/data/projects.json";

@Component({ name: "geolocator", components: { Button } })
export default class Geolocator extends Vue {
  loading = false;
  message = "Определяем Ваше местоположение";
  error = false;

  get isHidden(): boolean {
    return !this.$store.state.locate;
  }

  projects = data.projects;
  mounted(): void {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        this.loading = true;
        this.locate(res.coords.latitude, res.coords.longitude).then((data) => {
          const project = this.projects.filter((x) => x.id == data.id);
          this.$store.commit("setProject", project[0]);
          setTimeout(() => {
            this.loading = false;
            this.message = `Ваш город - ${data.name}?`;
          }, 5000);
        });
      },
      () => {
        this.loading = false;
        this.message = "Нам не удалось определить Вашу геолокацию";
        this.error = true;
        this.$store.commit("setProject", this.projects[0]);
        setTimeout(() => {
          this.$store.commit("toggleLocate");
        }, 5000);
      }
    );
  }

  close(): void {
    this.$store.commit("toggleLocate");
  }

  wrong(): void {
    this.$store.commit("displayProjectList");
    this.$store.commit("toggleLocate");
  }

  locate(lat: number, lon: number): Promise<any> {
    const url = `https://api.carbery.online/project-by-coords?lat=${lat}&lon=${lon}&app_branch=3`;
    return fetch(url).then((r) => r.json());
  }
}
